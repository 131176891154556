<template>
  <div class="box">
    <img :src="merchant.logo" class="logo img" v-if="merchant.logo">
    <img src="https://i.gtimg.cn/club/item/face/img/2/16022_100.gif" class="logo img" v-else>
    <div class="t">
      <div class="title">{{ merchant.title }}</div>
      <div class="stitle">{{ merchant.version }} <span style="color: orange">{{ merchant.over_time }} 到期</span></div>
      <div class="mg-tp-10">
        <el-button type="primary" size="small" @click="getPreCode(1)">授权公众号</el-button>
        <el-button type="warning" size="small" @click="getPreCode(2)">授权小程序</el-button>
      </div>
    </div>
    <div class="clr"></div>
    <div class="list">
      <div class="red mg-bt-10" v-if="showOpenTips"><i class="el-icon-warning-outline"></i> 您绑定开放平台不一致，将会导致会员不合并！</div>
      <div class="item" v-for="(item,index) in list" :key="index">
        <div class="top">
          <div>{{ item.type==1?'公众号':'小程序' }}
<!--            <span>审核中 <i class="el-icon-warning-outline" style="background-color: #fff; cursor: pointer" title="耐心等待"></i></span>-->
          </div>
          <div v-if="item.type==1">{{ item.service_type==2?'服务号':'订阅号' }} {{ item.verify_type==-1?'未认证':'已认证' }}</div>
          <div v-if="item.type==2 && item.audit">
            <i :class="{check: item.audit.verify==1}"></i>
            {{item.audit.version}}
            <el-popconfirm
                title="确定升级该小程序吗？"
                @confirm="upgradeVersion()"
                v-if="item.version"
            >
              <el-button slot="reference" type="text" size="small">升级</el-button>
            </el-popconfirm>
          </div>
        </div>
        <div class="ma">
          <img class="img pt" :src="item.qrcode" @click="showQrcode(item)" />
          <div class="cover"></div>
        </div>
        <div class="in">
          <img class="img" :src="item.head_img" />
          <div class="">
            <div class="tit">{{ item.nick_name }}</div>
            <div class="time">{{ item.ftime }}</div>
          </div>
        </div>
        <div class="btns">
<!--          <div class="btn"><i class="el-icon-s-data"></i>数据</div>-->
<!--          <div class="btn" v-if="item.type==1"><i class="el-icon-s-tools"></i>设置</div>-->
          <template v-if="item.type==2">
            <div class="btn" v-if="item.audit && item.audit.verify==1"><i class="el-icon-time"></i>审核成功</div>
            <div class="btn" @click="showSubmit(item)" v-else-if="item.audit && (item.audit.verify==2 || item.audit.status==2)"><i class="el-icon-time"></i>审核失败</div>
            <div class="btn" @click="showSubmit(item)" v-else-if="item.audit"><i class="el-icon-time"></i>审核中</div>
            <div class="btn" @click="showSubmit(item)" v-else><i class="el-icon-time"></i>提交审核</div>
            <div class="btn" @click="getPreCode(item.type)"><i class="el-icon-refresh"></i>重新授权</div>
          </template>
          <template v-if="item.type==1">
            <div class="btn" @click="getPreCode(item.type)" style="width: 100%"><i class="el-icon-refresh"></i>重新授权</div>
          </template>
        </div>
      </div>
    </div>
    <el-dialog
        title="提交审核"
        :visible.sync="showModal"
        :destroy-on-close="true"
        width="650px">
      <xcx-submit :audit="auditInfo" :callback="res=>{showModal=false; getWeixinList()}"></xcx-submit>
    </el-dialog>
    <el-dialog :title="qrcodeTitle" :visible.sync="showQrcodeModal" v-if="showQrcodeModal" custom-class="dialog" width="390px">
      <img :src="qrcode" width="350" />
    </el-dialog>
  </div>
</template>
<script>

import XcxSubmit from "@/components/Weixin/XcxSubmit";
export default {
  components: {
    XcxSubmit
  },
  data() {
    return {
      info: false,
      source_domain: this.config.SOURCE_DOMAIN,
      merchant: false,
      list: false,
      showModal: false,
      showQrcodeModal: false,
      qrcodeTitle: '',
      qrcode: '',
      auditInfo: {},
      showOpenTips: false
    };
  },
  created() {
    this.getWeixinList()
    this.getSysInfo()
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    showQrcode(item) {
      if(item.type==1) {
        this.qrcodeTitle = '公众号二维码'
      } else if(item.type==2) {
        this.qrcodeTitle = '小程序码'
      }
      this.qrcode = item.qrcode
      this.showQrcodeModal = true
    },
    showSubmit(item) {
      this.auditInfo = item.audit ? item.audit : {}
      this.showModal = true
    },
    getPreCode(type) {
      var params = {
        url: document.location.href,
        type: type
      }
      this.$api.merchant.getPreCode(params,res=> {
        if(res.errcode == 0) {
          window.location.href = res.data.url
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    getWeixinList() {
      this.$api.weixin.weixinList({},res=> {
        if(res.errcode == 0) {
          this.list = res.data
          var opens = []
          this.list.forEach(item=>{
            if(opens.indexOf(item.open_appid) === -1) {
              opens.push(item.open_appid)
            }
          })
          this.showOpenTips = opens.length>1
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    getSysInfo() {
      this.$api.merchant.sysDetail(res=> {
        if(res.errcode == 0) {
          this.merchant = res.data
        } else {
          this.fail(res.errmsg)
        }
      })
    },
    upgradeVersion() {
      this.showLoading()
      this.$api.weixin.weixinXcxUpgrade({}, res => {
        this.hideLoading()
        if(res.errcode == 0) {
          this.getWeixinList()
          this.success(res.errmsg)
        } else {
          this.fail(res.errmsg)
        }
      })
    }
  }
};
</script>
<style scoped>
.box {
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
}
.box .img {
  width: 100px;
  border-radius: 50%;
  float: left;
  margin-right: 20px;
}
.box .t {
  float: left;
  font-size: 16px;
  font-weight: 500;
}
.title {
  font-size: 14px;
}
.box .stitle {
  font-size: 12px;
  font-weight: 400;
  margin-top: 8px;
  padding-bottom: 8px;
}
.list {
  margin-top: 40px;
}
.list .item {
  background-color: #f5f5f5;
  border-radius: 10px;
  overflow: hidden;
  box-sizing: border-box;
  margin: 0 10px 10px 0;
  width: 350px;
  float: left;
  border: 1px solid #f5f5f5;
  transition: all .3s ease-out 0s;
}
.list .item:hover {
  box-shadow: 6px 6px 6px #ccc;
}
.list .item .top {
  display: flex;
  box-sizing: border-box;
  line-height: 30px;
  padding: 0 10px;
}
.list .item .top div:last-child {
  margin-left: auto;
}
.list .top i {
  margin-right: 5px;
  background-color: #ccc;
  border-radius: 50%;
  display: inline-block;
  width: 10px;
  height: 10px;
}
.list .top i.check {
  background-color: green;
}
.list .ma {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 150px;
  background: url("../assets/images/bj.jpg") no-repeat;
}
.list .ma .cover {
  position: absolute;
  background-color: rgba(0,0,0,0.4);
  width: 100%;
  height: 100%;
  z-index: 10;
}
.list .ma img {
  z-index: 11;
  margin-left: 130px;
  margin-top: 25px;
  position: absolute;
  border-radius: 10px;
}
.list .in {
  display: inline-block;
  width: 100%;
  padding: 10px;
  background-color: #fff;
}
.list .in img {
  width: 60px;
}
.list .in .tit {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 6px;
  font-weight: bold;
  color: rgb(102, 102, 102);
}
.list .btns {
  display: flex;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  box-sizing: border-box;
}
.list .btns .btn {
  width: 50%;
  text-align: center;
  cursor: pointer;
  border-right: 1px solid #ddd;
}
.list .btns .btn:last-child {
  border-right: 0;
}
.list .btns .btn i {
  color: #666;
  margin-right: 5px;
}
</style>
