<template>
  <div class="">
    <el-form :model="info" label-position="left" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
      <el-form-item label="服务类目" prop="cateid">
          <el-select v-model="info.cateid" placeholder="请选择类目" size="small">
            <el-option :label="item.first_class + '>' + item.second_class" :value="index" v-for="(item,index) in categoryList" :key="index"></el-option>
          </el-select>
        <div><i class="el-icon-warning-outline"></i> 如找不到服务类目，请先到微信公众平台“基本设置”中添加服务类目。</div>
      </el-form-item>
      <el-form-item label="标签" prop="label">
        <el-input placeholder="请输入标签" v-model="label" style="width: 250px">
          <el-button slot="append" type="primary" @click="addLabel">添加</el-button>
        </el-input>
        <div><i class="el-icon-warning-outline"></i> 填写标签，小程序更容易搜索到。</div>
        <div class="">
          <el-tag
              v-for="(item,index) in info.label"
              :key="index"
              class="mg-rt-10 mg-bt-5"
              closable
              @close="delLabel(index)"
              type="info">
            {{item}}
          </el-tag>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" disabled v-if="audit && audit.verify==1">审核成功</el-button>
        <el-button type="primary" disabled v-else-if="audit && (audit.status==0 || ((audit.status==1 || audit.status==3) && audit.verify==0))">审核中</el-button>
        <el-button type="primary" @click="submitForm" v-else-if="audit && (audit.status==2 || audit.verify==2)">审核失败，重新提交</el-button>
        <el-button type="primary" @click="submitForm" v-else>提交审核</el-button>
        <div v-if="audit && audit.remark"><i class="el-icon-warning-outline"></i> 审核失败：<br><span v-html="audit.remark"></span></div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      categoryList: [],
      label: '',
      info: {
        cateid: '',
        label: []
      },
      rules: {
        cateid: [
          { required: true, message: '请选择服务类目', trigger: 'blur' }
        ],
        label: [
          { required: true, message: '请添加服务标签', trigger: 'blur' }
        ],
      }
    }
  },
  components: {
  },
  props: {
    audit: Object,
    callback: Function
  },
  watch: {
  },
  created() {
    this.getCategoryList();
  },
  mounted() {
  },
  methods: {
    getCategoryList() {
      if(this.audit && this.audit.tag) {
        var label = JSON.parse(this.audit.tag)
        this.info.label = JSON.parse(this.audit.tag)
      }
      this.$api.weixin.weixinXcxCategory({}, res => {
        if(res.errcode==0) {
          this.categoryList = res.data
          if(this.categoryList) {
            this.categoryList.forEach((item,index) => {
              if(item.first_id==this.audit.first_id && item.second_id==this.audit.second_id) {
                this.info.cateid = index
              }
            })
          }
        }
      })
    },
    addLabel() {
      if(this.label=='') {
        this.fail('标签不能为空')
        return false
      }
      if(this.info.label.length>10) {
        this.fail('最多只能添加10个标签')
        return false
      }
      if(this.label.length>20) {
        this.fail('标签长度至多20字')
        return false
      }
      if(this.info.label.indexOf(this.label)>-1) {
        this.fail('该标签已添加')
        return false
      }
      this.info.label.push(this.label)
      this.label = ''
    },
    delLabel(e) {
      this.info.label.splice(e,1)
    },
    submitForm() {
      var params = this.categoryList[this.info.cateid]
      params.tag = this.info.label
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.showLoading()
          this.$api.weixin.weixinXcxSubmit(params, res => {
            if (res.errcode == 0) {
              this.hideLoading()
              this.success(res.errmsg)
              this.callback()
            } else {
              this.fail(res.errmsg)
            }
          })
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style scoped>
</style>
